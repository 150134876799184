<template>
  <a-spin tip="Loading..." :spinning="loading">
    <div class="login"></div>
  </a-spin>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { iosLoginOrRegist } from '@/utils'
import storage from 'store'

export default defineComponent({
  setup () {
    const loading = ref(true)

    return {
      loading
    }
  },

  created () {
    const code = this.$route.query.code

    if (code) {
      this.$store.commit('SET_PORT', 'scan')
      this.$store.dispatch('WxGZHLogin', code).then(() => {
        iosLoginOrRegist('/scan/login')
        this.$message.success('扫码成功')
        const peopleCount = storage.get('SCAN_PEOPLE_COUNT', 0)
        if (peopleCount > 0) {
          this.$router.push({ name: 'scan-index' })
        } else {
          this.$router.push({ name: 'scan-people' })
        }
      })
    } else {
      const tableId = this.$route.query.tableId
      const storeId = this.$route.query.storeId
      this.$store.commit('SET_SCAN_TABLE_ID', tableId)
      this.$store.commit('SET_SCAN_STORE_ID', storeId)

      this.$store.dispatch('SnsapiBase2')
    }
  }
})
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
}
</style>
